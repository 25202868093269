<template>
  <div>
    <div v-show="loading" class="spinner-container">
      <b-spinner label="Carregando..."></b-spinner>
    </div>
    <div class="mainClass" v-show="!onlyVisualize">
      <span class="mainSpan">
        Adicione ou tire uma
        <span class="mainSpan spanSecundario">
          foto do material cadastrado
        </span>
        <br />
      </span>
    </div>
    <div v-show="!loading">
      <div class="image-modal">
        <div
          :class="
            previewImages.length == 0 && !videoInit
              ? 'dot-border-cloud'
              : 'dot-border-images'
          "
          v-show="previewImages.length > 0 || !videoInit"
        >
          <img
            src="@/assets/img/upload-cloud.svg"
            class="cloud"
            v-show="previewImages.length == 0 && !videoInit"
          />
          <p v-show="previewImages.length == 0 && !videoInit">
            JPEG, JPG, PNG menores do que 10MB
          </p>
          <div class="image-container" v-if="previewImages.length != 0">
            <div :key="index" v-for="(image, index) in previewImages">
              <b-overlay
                :show="false"
                :class="index % 2 == 0 ? 'image-overlay-left' : ''"
                rounded="sm"
              >
                <div
                  :class="
                    index % 2 == 0
                      ? 'div-image-loading image-overlay-left'
                      : 'div-image-loading'
                  "
                  v-if="!isImageLoaded(index)"
                >
                  <b-spinner class="spinner-image" />
                </div>
                <b-img
                  class="image-card"
                  :src="image.url"
                  width="208px"
                  height="156px"
                  v-on:load="onImageLoaded(index)"
                  v-show="isImageLoaded(index)"
                ></b-img>
                <b-button
                  class="image-button"
                  @click="removeImg(index)"
                  variant="danger"
                  v-if="!onlyVisualize && isImageLoaded(index)"
                  >x</b-button
                >
                <b-checkbox
                  v-model="previewImages[index].checked"
                  @change="checkPhoto($event, index)"
                  class="image-checkbox"
                  size="lg"
                  variant="danger"
                  v-if="downloadRoute && isImageLoaded(index)"
                />
              </b-overlay>
            </div>
          </div>
        </div>

        <div class="image-upload" v-if="!onlyVisualize">
          <!-- <b-avatar
            v-show="previewImages.length == 0 && !videoInit"
            square
            size="10rem"
          ></b-avatar> -->
          <video
            v-show="videoInit"
            ref="video"
            autoplay
            :height="height"
            :width="width"
          ></video>
          <div class="text-center p-3 mt-1">
            <!-- <b-button
              :disabled="loadImage"
              v-if="!videoInit"
              variant="outline-secondary"
              @click="initWebCam"
            >
              Tirar foto usando a webcam
            </b-button> -->
            <button
              v-b-tooltip.hover.top="'Tirar foto'"
              class="btnCamera"
              variant="light"
              v-if="!videoInit"
              @click="initWebCam"
            >
              <camera-icon size="2.5x" class="iconCamera"></camera-icon>
            </button>
            <b-button
              v-if="videoInit"
              variant="outline-secondary"
              @click="takePicture"
            >
              Tirar foto
            </b-button>
          </div>
          <b-form-file
            :disabled="loadImage && videoInit"
            placeholder="Selecione um arquivo de imagem"
            accept=".jpg, .jpeg, .png"
            @change="uploadImage"
            browse-text="Escolher arquivo"
          ></b-form-file>
        </div>
      </div>
      <div class="text-right" style="padding-top: 15px" v-if="buttonConfirmar || checkedPhotosIndex.length">
        <div
          class="text-left"
          style="display: inline-block; float: left"
          v-if="checkedPhotosIndex.length"
        >
          <b-button
            class="primary-contained-button lg"
            @click="downloadCheckedPhotos"
            v-if="downloadRoute"
            >Download de fotos</b-button
          >
          <b-button
            class="primary-contained-button lg"
            @click="deleteCheckedPhotos"
            v-if="!onlyVisualize"
            >Deletar fotos</b-button
          >
        </div>
        <b-button class="primary-contained-button" @click="confirmar" v-if="buttonConfirmar"
          >Confirmar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';

import GenericApi from '@/services/genericRequest';

export default {
  name: 'photo-input-form',
  props: {
    width: {
      type: Number,
      default: 320,
    },
    height: {
      type: Number,
      default: 240,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Adicione Fotos',
    },
    onlyVisualize: {
      type: Boolean,
      default: false,
    },
    buttonConfirmar: {
      type: Boolean,
      default: false,
    },
    downloadRoute: {
      type: String,
      required: false,
    },
    photosName: {
      type: String,
      required: false,
      default: 'foto',
    },
    countPhotos: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      loadImage: false,
      previewImages: [],
      videoInit: false,
      video: null,
      videoTracks: null,
      error: {
        unableToAcessWebCam: false,
        unableToLoadModels: false,
        message: '',
      },
      loading: true,
      imageLoaded: [],
      checkedPhotosIndex: [],
      firstImageUrl: '',
      countPhotosMaterial: 0,
    };
  },

  methods: {
    initWebCam() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.video.srcObject = stream;
          this.videoTracks = stream.getVideoTracks();
          this.videoInit = true;
        })
        .catch((error) => {
          this.error.unableToAcessWebCam = true;
          this.error.message = error;
        });
    },

    newImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener('load', () => {
          resolve(img);
        });
        img.addEventListener('error', (error) => {
          reject(error);
        });
        img.src = src;
      });
    },

    async takePicture() {
      if (this.countPhotos + this.countPhotosMaterial < 30) {
        const img = document.createElement('img');
        const canvas = document.createElement('canvas');
        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;
        canvas
          .getContext('2d')
          .drawImage(this.video, 0, 0, canvas.width, canvas.height);
        img.src = canvas.toDataURL();
        const newImage = await this.newImage(img.src);
        const resizedData = this.resizeImage(newImage, 800);
        console.log(resizedData);
        this.previewImages.push({ url: resizedData });
        this.loadImage = true;
        this.returnPhotos();
      } else {
        swal({
          title: 'Número máximo de fotos adicionado',
          text: 'Não é possível adicionar mais fotos',
          icon: 'warning',
          button: 'OK',
        });
      }
    },

    uploadImage(event) {
      if (this.countPhotos + this.countPhotosMaterial < 30) {
        const reader = new FileReader();
        reader.addEventListener('load', async (imgLoadEvent) => {
          const img = await this.newImage(imgLoadEvent.target.result);
          const resizedData = this.resizeImage(img, 800);
          console.log(resizedData);
          this.previewImages.push({ url: resizedData });
          this.loadImage = true;
          this.returnPhotos();
        });
        reader.readAsDataURL(event.target.files[0]);
        this.countPhotosMaterial += 1;
      } else {
        swal({
          title: 'Número máximo de fotos adicionado',
          text: 'Não é possível adicionar mais fotos',
          icon: 'warning',
          button: 'OK',
        });
      }
    },

    async returnPhotos() {
      this.videoInit = false;
      this.loadImage = false;
      this.$emit('newPhoto', this.previewImages);
    },

    removeImg(index) {
      this.previewImages.splice(index, 1);
      this.$emit('newPhoto', this.previewImages);
      this.countPhotosMaterial -= 1;
    },

    checkPhoto(checked, photoIndex) {
      if (checked && !this.checkedPhotosIndex.includes(photoIndex)) {
        this.checkedPhotosIndex.push(photoIndex);
      } else if (!checked) {
        const index = this.checkedPhotosIndex.indexOf(photoIndex);
        if (index !== -1) this.checkedPhotosIndex.splice(index, 1);
      }
    },

    async downloadCheckedPhotos() {
      const checkedPhotos = this.checkedPhotosIndex.map((photoIndex) => this.previewImages[photoIndex]);
      const chekedPhotosWithUrl = [];
      const checkedPhotosUploaded = [];
      checkedPhotos.forEach((photo) => {
        if (photo.url.slice(0, 4).includes('http')) chekedPhotosWithUrl.push(photo);
        else checkedPhotosUploaded.push(photo);
      });
      const photosApi = await this.getPhotosFromApi(chekedPhotosWithUrl);
      const photosBase64 = this.getPhotosFromLocal(checkedPhotosUploaded);

      const photosBlob = [...photosApi, ...photosBase64];

      const fileLink = document.createElement('a');
      if (photosBlob.length > 1) {
        const zip = this.createZip(photosBlob);
        zip.generateAsync({ type: 'blob' }).then((zipFile) => {
          const fileURL = window.URL.createObjectURL(zipFile);
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.photosName}.zip`);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      } else {
        let typeImage = '';
        if (photosApi.length) typeImage = 'jpeg';
        else typeImage = this.getTypeImageBase64(checkedPhotosUploaded[0].url);
        const fileURL = window.URL.createObjectURL(photosBlob[0]);
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${this.photosName}.${typeImage}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },

    async getPhotosFromApi(photos) {
      const route = this.downloadRoute;
      const urls = photos.map((photo) => photo.url);
      const fotos = await GenericApi.getWithoutPagination(
        {
          filters: { photosUrl: JSON.stringify(urls) },
        },
        route,
      );

      const photosApiBlob = fotos.map((photoBuffer) => this.buffersToBlob(photoBuffer));
      return photosApiBlob;
    },

    deleteCheckedPhotos() {
      const checkedPhotosIndex = this.checkedPhotosIndex.sort((a, b) => b - a);
      checkedPhotosIndex.forEach((checkedPhotoIndex) => this.removeImg(checkedPhotoIndex));
      this.uncheckAllPhotos();
    },

    uncheckAllPhotos() {
      this.checkedPhotosIndex = [];
      this.previewImages.forEach((item, index) => {
        this.previewImages[index].checked = false;
      });
    },

    buffersToBlob(photoBuffer) {
      const buffer = Buffer.from(photoBuffer.Body);
      return new Blob([buffer], { type: 'image/jpeg' });
    },

    createZip(blobArchives) {
      const zip = new JSZip();
      let photosCount = 0;
      blobArchives.forEach((item) => {
        photosCount++;
        const file_name = `${this.photosName}_${photosCount}.jpeg`;
        zip.file(file_name, item, { binary: true });
      });
      return zip;
    },

    getPhotosFromLocal(photos) {
      const base64Photos = photos.map((photo) => photo.url);
      return base64Photos.map((data) => this.base64ToBlob(data));
    },

    base64ToBlob(dataInBase64) {
      let byteString;
      if (dataInBase64.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataInBase64.split(',')[1]);
      else byteString = unescape(dataInBase64.split(',')[1]);
      // separate out the mime component
      const mimeString = dataInBase64.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    getTypeImageBase64(base64Url) {
      return base64Url.split(',')[0].split(':')[1].split(';')[0].split('/')[1];
    },

    async confirmar() {
      this.$emit('confirmar');
    },
    async onImageLoaded(imageIndex) {
      this.imageLoaded.push(imageIndex);
    },
    isImageLoaded(imageIndex) {
      return this.imageLoaded.includes(imageIndex);
    },
    resizeImage(imgEl, wantedWidth) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const whidtIdeal = wantedWidth > imgEl.width ? imgEl.width : wantedWidth;
      const aspect = imgEl.width / imgEl.height;

      canvas.width = whidtIdeal;
      canvas.height = whidtIdeal / aspect;
      ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL();
    },
  },
  mounted() {
    this.video = this.$refs.video;
    this.loading = false;
    if (this.photos.length) {
      this.previewImages = this.photos;
    }
  },

  beforeDestroy() {
    if (this.videoTracks !== null) {
      this.videoTracks.forEach((track) => track.stop());
    }
  },
};
</script>

<style lang="scss" scoped>
input[type='file']::-webkit-file-upload-button {
  border: 1px solid grey;
  border-radius: 5px;
  height: 40px;
  display: none;
  background: #fffaaa;
}

.mainSpan {
  text-align: center;
  align-content: center;
}

.spanSecundario {
  color: #00daae;
}
.button-photo-confirmar {
  top: 20px;
  right: 0px;
}
.mainClass {
  text-align: center;
  margin-bottom: 24px;
  padding: 0 20%;
}

.dot-border-cloud {
  border: 2px dashed rgba(175, 180, 214, 1);
  border-radius: 5px;
  padding: 10px;
  width: 92%;
  margin-left: -3%;
  padding-bottom: 10px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.dot-border-images {
  border: 2px dashed rgba(175, 180, 214, 1);
  border-radius: 5px;
  padding: 10px;
  width: 92%;
  margin-left: -3%;
  padding-bottom: 10px;
  margin-top: 1rem;
}

.iconCamera {
  vertical-align: -webkit-baseline-middle;
  color: rgba(32, 159, 133, 1);
}

.btnCamera {
  border-color: rgba(247, 247, 250, 1);
  box-shadow: white;
  border-width: 0px;
  width: 49px;
  height: 37px;
  border-radius: 6px;
  background-color: rgba(247, 247, 250, 1);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.image-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-button {
  border-radius: 50%;
  top: -3%;
  position: absolute;
  left: 80%;
}

.image-checkbox {
  top: -3%;
  left: 5%;
  position: absolute;
  width: 50px;
}

.image-card {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.image-modal {
  margin: auto;
  display: flex;
  width: 500px;
  flex-direction: column;
}

.image-upload {
  margin-top: 1rem;
  margin-left: -3%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 92%;
  justify-content: center;
}

.no-photo {
  align-items: center;
}

.spinner-image {
  position: relative;
  color: #209f85;
  width: 2rem;
  height: 2rem;
  top: 41%;
}

.div-image-loading {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  width: 208px;
  height: 156px;
  border: 1px dashed rgba(175, 180, 214, 1);
}
</style>
